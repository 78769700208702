import React from 'react'
import styled from 'styled-components'

const TableHeader = styled.div`

.es-g-shipment-table-titles {
  border-bottom: solid 1px #bbb;
  margin: 0 auto;
    max-width: 1360px;
  .es-sg-shipment-table-titles {
    font-family: "Open Sans";
    margin: 0 auto;
    max-width: 1360px;
    margin-top: 20px;
    font-size: 10px;
    font-weight: 700;
    color: #666;
    text-transform: uppercase;
    // 📰
    display: grid;
    grid-template-rows: minmax(0.5fr, 0.5fr) 0.5fr;
    grid-template-columns: repeat(12, 1fr); //shorthand for 12 columns
    grid-template-areas:
    ". sg_title_1 sg_title_2 sg_title_3 sg_title_4 sg_title_5 sg_title_6 sg_title_7 sg_title_8 . . .";

    .sg-title-1 {
      grid-area: sg_title_1; // 🗺
    }
    .sg-title-2 {
      grid-area: sg_title_2; // 🗺
    }
    .sg-title-3 {
      grid-area: sg_title_3; // 🗺
    }
    .sg-title-4 {
      grid-area: sg_title_4; // 🗺
    }
    .sg-title-5 {
      grid-area: sg_title_5; // 🗺
    }
    .sg-title-6 {
      grid-area: sg_title_6; // 🗺
    }
    .sg-title-7 {
      grid-area: sg_title_7; // 🗺
    }
    .sg-title-8 {
      grid-area: sg_title_8; // 🗺
    }
  }
}




`

const EchoShipMiscComponent = () => {
  return (
    <TableHeader>
      <div className="es-g-shipment-table-titles">
        <div className="es-sg-shipment-table-titles">
          <div className="sg-title-1">SHIPMENT</div>
          <div className="sg-title-2">STATUS</div>
          <div className="sg-title-3">ORIGIN</div>
          <div className="sg-title-4">DESTINATION</div>
          <div className="sg-title-5">PICKUP</div>
          <div className="sg-title-6">DELIVERY</div>
          <div className="sg-title-7">CARRIER</div>
          <div className="sg-title-8">TOTAL COST</div>
        </div>
      </div>
    </TableHeader>  
  )
}

export default EchoShipMiscComponent;
