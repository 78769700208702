import React from 'react'
import styled from 'styled-components'

import '../styles/spectre.css';

const InputSelectTemplate = styled.div`

.form-group.select-template {

  font-family: "Open Sans";


  label.form-label {
    margin-bottom: 5px;
    font-weight: 700;
    color: #666;
    font-size: 14px;
    font-weight: 600;

    span {
      color: #26c1fb;
    }
  }
}
`;

const EchoShipInput = (props) => {
  return (
    <InputSelectTemplate>
      <div className="es-sg-delivery-date">

      <div
        className="form-group select-template"
        style={{
          width: "80%"
        }}
      >
        <label
          className="form-label label-lg"
        >
          {props.labelTitle}
        </label>
        <input
          className="form-input input-lg"
          type="text"
          placeholder={props.placeholder}
          icon="calendar"
        />
      </div>
 
      </div>
    </InputSelectTemplate>
  )
}

export default EchoShipInput;